<template>
  <div class="full-page">
    <div class="full-page-container">
      <div class="full-top"></div>

      <div class="full-middle">
        <div class="login-container-wrapper">
          <div class="login-container">
            <div class="text-center">
              <img src="/img/logos/alfathbelajarlogo.svg" alt="Logo" />
            </div>

            <b-alert
              class="mt-2"
              :show="alert.dismissCountDown"
              dismissible
              :variant="alert.variant"
              @dismissed="alert.dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <p class="mb-0">{{ alert.message }}</p>
            </b-alert>
            <b-form @submit.stop.prevent="loginNow">
              <label for="login-email">Email</label>
              <b-input
                v-model="loginObj.email"
                :state="validationEmail"
                v-on:keyup.enter="loginNow"
                type="email"
                id="login-email"
              >
              </b-input>
              <b-form-invalid-feedback v-if="!validationEmail">
                Please enter a valid email.
              </b-form-invalid-feedback>
              <br />

              <label for="login-password">Password</label>
              <b-input
                v-model="loginObj.password"
                v-on:keyup.enter="loginNow"
                id="login-password"
                :state="validationPassword"
                type="password"
              >
              </b-input>
              <b-form-invalid-feedback v-if="!validationPassword">
                Password must be at least 6 characters long.
              </b-form-invalid-feedback>
              <br />

              <!-- <router-link to="forgot-password">
                Lupa password?
              </router-link> -->
            </b-form>
            <br />

            <my-button
              v-show="!showLoading"
              class="w-100"
              type="blue"
              @click="loginNow"
            >
              MASUK
            </my-button>

            <my-button
              variant="primary"
              class="w-100"
              type="blue"
              disabled
              v-show="showLoading"
            >
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </my-button>
          </div>
        </div>
      </div>

      <div class="full-bottom"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      alert: {
        variant: "success",
        message: "Success",
        response: "",
        dismissCountDown: 0
      },
      loginObj: {
        email: "",
        password: "",
        other: false
      },
      showLoading: false,
      formSubmitted: false
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: types.IS_LOGGED_IN
    }),
    validationEmail() {
      if (!this.formSubmitted) {
        return null;
      }
      // eslint-disable-next-line
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.loginObj.email
      );
    },
    validationPassword() {
      if (!this.formSubmitted) {
        return null;
      }
      return (
        this.loginObj.password.length >= 6 &&
        this.loginObj.password.length <= 100
      );
    }
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },

  methods: {
    ...mapActions({
      login: types.LOGIN,
      logout: types.LOGOUT
    }),
    loginNow() {
      this.formSubmitted = true;
      if (this.validationEmail && this.validationPassword) {
        this.showLoading = true;
        this.login(this.loginObj)
          .then(response => {
            // eslint-disable-next-line
            var res = response;
            this.showLoading = false;
            this.$router.push("/dashboard");
          })
          .catch(error => {
            this.showLoading = false;
            this.alert.variant = "danger";
            this.alert.message = error;
            this.alert.response = error;
            this.showAlert(30);
          });
      }
    },
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
    showAlert(seconds) {
      this.alert.dismissCountDown = seconds;
    }
  }
};
</script>
